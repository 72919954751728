<template>
  <!-- 區塊1 開始-->
  <div class="container-fluid p-0 mt-0 position-relative" style="z-index: 10">
    <div
      class="head-bg-1 d-flex justify-content-center align-items-center"
    >
      <img
        class="head-bg-3"
        src="/images/home/head/page-head-1.png"
      />
    </div>
  </div>
  <!-- 區塊1 結束-->
</template>

<script>
export default {
  name: "PageBanner",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.head-bg-1 {
  /* background-image: url("/images/home/head/bg-1.png");
  background-position: top;
  background-repeat: repeat; */
  background-color: white;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 350px;
  background: url("/images/home/head/bg-1.png") repeat 0 0;
}
.head-bg-2 {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  /* height: 980px; */
  z-index: 1;
  top: 0px;
  left: 1080px;
  animation-delay: 0.5s;
}
.head-bg-3 {
  position: relative;
  width: 50%;
  height: auto;
  z-index: 2;
  top: 0px;
  animation-delay: 0.5s;
}

@media screen and (max-width: 700px) {
  .head-bg-1 {
    height: 200px;
  }
  .head-bg-2 {
    width: 100%;
    height: auto;
    display: none;
  }
  .head-bg-3 {
    width: 100%;
    top: 20px;
  }
}
</style>
